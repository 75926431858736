import React, { useRef } from "react";
import Header from "../../components/Layout/Header/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import MainCategory from "../../components/Home/MainCategory";
import Footer from "../../components/Layout/Footer/Footer";
import Swal from "sweetalert2"; // Import SweetAlert2

const Home = () => {
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = {
      from_name: form.current.name.value,
      from_email: form.current.email.value,
      from_mobile: form.current.mobile.value,
      message: form.current.message.value,
    };

    try {
      const response = await fetch("/api/contact_us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Message Sent!",
          text: result.message, // "Message sent successfully!"
        });
        form.current.reset(); // Clear the form after successful submission
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: result.error || "Failed to send message. Please try again later.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return (
    <div>
      <Header />
      <Carousel autoPlay={true} infiniteLoop={true} interval={3000}>
        <div>
          <img src="images/slide_image_1.jpeg" alt="Slide 1" />
        </div>
        <div>
          <img src="images/slide_image_2.jpeg" alt="Slide 2" />
        </div>
      </Carousel>

      <MainCategory />

      {/* ======= Contact Section ======= */}
      <section id="contact" className="contact">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact Us</h2>
          </div>

          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt" />
                  <h4>Location:</h4>
                  <p>Dupagul, Airport, Dhopagul, Sylhet, Bangladesh</p>
                </div>
                <div className="email">
                  <i className="bi bi-envelope" />
                  <h4>Email:</h4>
                  <p>alamanastonecrusher@gmail.com</p>
                </div>
                <div className="phone">
                  <i className="bi bi-phone" />
                  <h4>Call:</h4>
                  <p>+8801755-673963</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-5 mt-lg-0">
              <form ref={form} onSubmit={sendEmail} method="post" className="php-email-form">
                <div className="row gy-2 gx-md-3">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="from_name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="from_email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div className="form-group col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="from_mobile"
                      id="mobile"
                      placeholder="Mobile"
                      required
                    />
                  </div>
                  <div className="form-group col-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Message"
                      required
                    />
                  </div>
                  <div className="text-center col-12">
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* End Contact Section */}
      <Footer />
    </div>
  );
};

export default Home;
